import { DialogContent } from '@troon/ui';
import { createAsync } from '@solidjs/router';
import { AccessProducts } from '../access-products';
import { usePersisted } from '../../providers/persistence-store';
import { getAccessProducts } from '../../providers/card';
import type { ComponentProps } from 'solid-js';

type Props = ComponentProps<typeof AccessProducts>;

export function AccessProductDialogContent(props: Props) {
	const [params] = usePersisted();
	createAsync(() => getAccessProducts({ promoCode: params.promoCode?.value }), { deferStream: true });

	return (
		<DialogContent width="xl" height="fit" header="Choose your Troon Access" headerLevel="h3" noPadding>
			<div class="px-4 pb-4 md:px-6 md:pb-6">
				<AccessProducts {...props} />
			</div>
		</DialogContent>
	);
}
